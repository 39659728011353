<template>
  <div class="about no_touch">
    <h3 @click="get">This is an about page</h3>
    <h3 @click="get2">This is an about page2</h3>
  </div>
</template>

<script>

// const axios = require('axios').default;
import axios from 'axios'

export default {
  name: 'About',
  methods: {
    get(){
      const axios = require('axios');

      axios.post('http://api.xiaoxinhuan.com/api/Device/PersonTracking', {
        "DeviceId": 2679798,
        "Language": "zh-CN",
        "MapType": "baidu",
        "Token": "DE079DC3BD26FF1A91DC995BE5F067CD57D65017B14C89C1FF56EA6164AF1FFF830A3DBD89DA2108",
        "UserId": 431192
      })
          .then(function (response) {
            // handle success
            console.log(response);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
    },
    get2(){
      const request = axios.create({
        timeout: 10 * 1000
      })

      request.get('/apis/Updater/GetPartnerAppInfo')
          .then(function (response) {
            // handle success
            console.log(response);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          });
    }
  }
}
</script>
